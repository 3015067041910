import productCatalogActions from "actions/product_catalog";
import { ClippedContent, Table, TableLoader } from "dyl-components";
import { useContext, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Select } from "semantic-ui-react";
import { StringUtils } from "utils";
import CategoryProductAddition from "./CategoryProductAddition";
import CategoryProductSummary from "./CategoryProductSummary";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const PricingSchedule = ({ product_id, price_data, control, disabled }) => {
    const { field: masterFilterField } = useController({
        control,
        name: "pricing_schedule"
    });
    const frequencies = Object.keys(price_data.price).filter(frequency => Boolean(price_data.price[frequency]?.active));
    return (
        <Controller
            control={control}
            name={`cart`}
            render={({ field: { name, value: cart, onChange } }) => {
                const value = cart.find(item => item.id === product_id)?.pricing_schedule;
                return (
                    <Select
                        name={name}
                        value={masterFilterField.value || value || frequencies[0]}
                        disabled={disabled || masterFilterField.value}
                        options={frequencies.map((frequency) => ({
                            key: frequency,
                            value: frequency,
                            text: StringUtils.capitalize(frequency),
                        }))}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value: cart.map(item => {
                                if (item.id !== product_id) {
                                    return item;
                                }
                                return {
                                    ...item,
                                    pricing_schedule: value
                                }
                            }) } });
                        } } />
                );
            }}
        />
    );
};

const CategoryProductRow = ({ product, onUpdateCart, isInCart, selectedPricingSchedule, opportunity_id, price }) => {
    const { control } = useFormContext();

    const [collapsed, setCollapsed] = useState(true);

    const dispatch = useDispatch();

    const hasAdditionalInformation = product.has_addons ||
        product.has_fees ||
        product.has_tax ||
        product.has_product_variation;

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed && hasAdditionalInformation) {
            dispatch(productCatalogActions.readProductSummary(product.id));
        }
    };

    const isVolumeBased = product.price_data?.model?.includes("volume");
    const hasToggler =
        hasAdditionalInformation ||
        isVolumeBased;

    const RowComponent = hasToggler ? Table.CollapsibleRow : Table.Row;

    const summary = useSelector(
        (state) => state.product_catalog.summaries[product.id]
    );

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);
    const { isUpsell } = quoteBuilderConfig;

    const shouldHaveOpportunityId = isUpsell ? false : !Boolean(opportunity_id);

    const rowComponentProps = hasToggler
        ? {
              onToggle,
              collapsed,
              toggleDisabled: summary?.loading,
              subrowContent: summary?.loading ? (
                  <TableLoader isLoading colspan={7} />
              ) : (
                  <CategoryProductSummary 
                    disabled={shouldHaveOpportunityId}
                    product_id={product.id}
                    price_data={product.price_data} 
                    volumeInformation={!isVolumeBased ? null : (
                        <>
                            <CategoryProductAddition 
                                label={<b>Bulk Prices</b>}
                            />
                            {product.price_data?.model === "volume" && product.price_data.price.map(range => (
                                <CategoryProductAddition 
                                    key={`${product.id}-bulk-price-${range.start}`}
                                    label={`${range.start}${range.end ? `-${range.end}` : " and above"}`}
                                    value={`$${range.price}`}
                                />
                            ))}
                            {product.price_data?.model === "volume_recurring" && (
                                product.price_data.price[selectedPricingSchedule]?.volumes.map(range => (
                                    <CategoryProductAddition 
                                        key={`${product.id}-bulk-price-${range.start}`}
                                        label={`${range.start}${range.end ? `-${range.end}` : " and above"}`}
                                        value={`$${range.price}`}
                                    />
                                ))
                            )}
                        </>
                    )}
                  />
              ),
          }
        : {};        

    return (
        <RowComponent {...rowComponentProps}>
            {!hasToggler && <Table.Cell width={1} />}
            <Table.Cell>
                <div style={{ maxWidth: "15em" }}>
                    <ClippedContent><b>{product.name}</b></ClippedContent>
                </div>
            </Table.Cell>
            <Table.Cell>
                {StringUtils.capitalize(product.product_type)}
            </Table.Cell>
            <Table.Cell>
                {!product?.price_data?.model?.includes("recurring") ? null : (
                    <PricingSchedule 
                        product_id={product.id} 
                        price_data={product.price_data} 
                        control={control} 
                        disabled={!isInCart} 
                    />
                )}
            </Table.Cell>
            <Table.Cell>
                {price}
            </Table.Cell>
            <Table.Cell>
                {product.discount ? `${product.discount}%` : ''}
            </Table.Cell>
            <Table.Cell style={{ textAlign: "center" }}>
                <Icon
                    className={`fas fa-circle-${
                        isInCart ? "minus" : "plus"
                    }`}
                    color={isInCart ? "red" : "primary"}
                    link
                    onClick={onUpdateCart}
                    disabled={shouldHaveOpportunityId || (!isInCart && product.has_product_variation)}
                />
            </Table.Cell>
        </RowComponent>
    );
};

export default CategoryProductRow;
