import { yup } from "dyl-components";

export const formatOrder = (items) => {
    return (items || [])
        .filter((item) => !Boolean(item.removed))
        .map((item) => {
            return {
                addons: item.addons.map((addon_id) => addon_id),
                product: {
                    cart_item_id: item.cart_item_id,
                    price_schedule: item.pricing_schedule,
                    product_id: item.id,
                    product_variation_id: item.variation_id,
                    quantity: Number(item.quantity),
                },
            };
        });
};

export const formatCartItems = ({ cart, ...data }) => {
    const { pricing_schedule } = data;
    return {
        ...data,
        items: (cart || []).filter(item => {
            return !item.pricing_schedule || !pricing_schedule || item?.schedules?.includes(pricing_schedule);
        }).map(
            ({
                id: product_id,
                pricing_schedule,
                variation_id: product_variation_id,
                quantity,
                schedules,
                ...item
            }) => ({
                ...item,
                product: {
                    product_id,
                    price_schedule: pricing_schedule || pricing_schedule,
                    product_variation_id,
                    quantity,
                },
            })
        ),
    };
};

export const getDefaultCartItems = (items) => {
    return (items || []).map((item) => ({
        id: item.product_id,
        pricing_schedule: item.pricing_schedule,
        variation_id: item.product_variation_id,
        quantity: item.quantity,
        addons: item.addon
            .filter((addon) => !addon.unlinked)
            .map((addon) => addon.id),
        cart_item_id: item.cart_item_id,
        removed: item.product_status === "inactive" ||
            item.product_status === "discontinued",
        discount: (item.product_discount || 0) === 0 ? 0 : item.item_discount || 0,
        max_discount: item.product_discount || 0,
        model: item.price_data?.model,
        schedules: item.price_data?.model?.includes("recurring") ? Object.keys(item.price_data?.price || {}).filter(frequency => Boolean(item.price_data?.price[frequency]?.active)) : []
    }));
};

export const cartItemSchema = {
    removed: yup.boolean().nullable(true),
    quantity: yup
        .lazy(value => value !== 0 && !Boolean(value) ? yup.string().when("removed", {
            is: false,
            then: schema => schema.required("Invalid quantity"),
            otherwise: schema => schema.nullable(true)
        }) : yup.number().positive("Invalid quantity")
        .integer("Invalid quantity")
        .typeError("Invalid quantity")),
    pricing_schedule: yup.string().when("model", {
        is: value => value?.includes("recurring"),
        then: schema => schema.required("This field is reqired"),
        otherwise: schema => schema.nullable(true)
    }),
    discount: yup.number()
        .typeError("Please enter a valid rate")
        .min(0, "Please enter a valid rate")
        .max(100, "Please enter a valid rate")
        .test("maxDigitsAfterDecimal", "Please enter a valid rate", (number) =>
            /^\d+(\.\d{1,2})?$/.test(number)
        )
        .test({
            name: "should_not_be_more_than_max",
            test: function(value, context) {
                if (context.parent.removed) {
                    return true;
                }
                const max_discount = context.parent.max_discount;
                return value <= max_discount || this.createError({
                    message: `Exceeds max`,
                    path: this.path
                });
            }
        }),
}
