import React, { useContext } from "react";
import { Table, EllipsisDropdown, ControlledPopup, Icon, HoverList } from "dyl-components";
import {
    DropdownItem,
    Dropdown,
    Checkbox,
    Header,
} from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { PhoneUtil } from 'utils';

const Row = ({ group, onEditRingGroupCallQueue, onRemove }) => {

    const [selectedItems, setSelectedItems] = useContext(BulkActionsContext);

    const isSelected = (extension_id) => {
        return selectedItems.includes(extension_id);
    };

    const onToggleExtension = (extension_id) => {
        const newSelectedExtensions = isSelected(extension_id)
            ? selectedItems.filter((id) => extension_id !== id)
            : [...selectedItems, extension_id];
        setSelectedItems(newSelectedExtensions);
    };

    const getMembersString = (arr, param, subParam) => {
        const namesArr = [];
        arr?.forEach(item => {
            let name = `Ext. ${item[param]}`;
            if (subParam && item[subParam]) {
                name = `${name}  ${item[subParam]?.first_name} ${item[subParam]?.last_name} `;
            }
            namesArr.push(name);
        });
        return namesArr;
    }

    const getIconName = (type) => {
        switch (type) {
            case 'ring_group':
                return "fa-solid fa-phone-volume";
            case 'call_queue':
                return "fa-solid fa-user-clock";
            default:
                return "";
        }
    };


    const getTextFromKey = (key) =>{
        const extension = PhoneUtil.DESTINATION_TYPES.find(ext => ext.key === key);
        return extension ? extension.text : null;
      }


    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(group.id)}
                    onChange={() => {
                        onToggleExtension(group.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>{group.label}</Table.Cell>
            <Table.Cell>
                <Icon className={getIconName(group.group_type)} />
                {getTextFromKey(group.group_type)}
            </Table.Cell>
            <Table.Cell>{getMembersString(group?.members?.data, 'extension_id',null).join(",")}</Table.Cell>
            <Table.Cell>{group.view ? "On" : "Off"}</Table.Cell>
            <Table.Cell className="receive-calls-container">
                    <HoverList 
                    list={ 
                        getMembersString(group?.members?.data, 'member', 'user') || []
                    }
                    scroll={true} 
                    /> 
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        style={{ cursor: "pointer" }}
                        text="Edit"
                        icon="fas fa-edit"
                        onClick={() => onEditRingGroupCallQueue(group,"edit")}
                    />
                    <DropdownItem
                        style={{ cursor: "pointer" }}
                        text="Duplicate"
                        icon="fas fa-copy"
                        onClick={() => onEditRingGroupCallQueue(group,"duplicate")}
                    />
                    <ControlledPopup
                        header={
                            <Header as="h4" textAlign="center">
                                Are you sure?
                            </Header>
                        }
                        trigger={
                            <Dropdown.Item
                                icon="fas fa-trash-alt"
                                text="Delete"
                                disabled={group.in_use}
                            />
                        }
                        onConfirm={() => onRemove(group.id, group.group_type)}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
};

export default Row;
