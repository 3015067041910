export default {
    LEAD: 'LEAD',
    LEADS: 'LEADS',
    MASTER_ACCOUNT_NOTES: 'MASTER_ACCOUNT_NOTES',
    ACCOUNT_NOTES: 'ACCOUNT_NOTES',
    AUTOMATION_TRIGGER: 'AUTOMATION_TRIGGER',
    AUTOMATION_TRIGGER_ORDER: 'AUTOMATION_TRIGGER_ORDER',
    AUTOMATION_TRIGGER_UNIQUE: 'AUTOMATION_TRIGGER_UNIQUE',
    AUTOMATION_USERS: 'AUTOMATION_USERS',
    AUTOMATION_USER_CAP: 'AUTOMATION_USER_CAP',
    ACCOUNT_STICKY_NOTES: 'ACCOUNT_STICKY_NOTES',
    LEAD_AGENDA: 'LEAD_AGENDA',
    LEAD_HISTORY: 'LEAD_HISTORY',
    LEAD_CONNECT: 'LEAD_CONNECT',
    SMS_HISTORY: 'SMS_HISTORY',
    CALENDAR: 'CALENDAR',
    HOTLIST: 'HOTLIST',
    CHAT_ROOMS: 'CHAT_ROOMS',
    CHAT_ROOM: 'CHAT_ROOM',
    NOTE: 'NOTE',
    OFFICE_VIEW: 'OFFICE_VIEW',
    CUSTOMERS: 'CUSTOMERS',
    USER: 'USER',
    USERS: 'USERS',
    SEARCH_USERS: 'SEARCH_USERS',
    FIND_BY_EMAIL: 'FIND_BY_EMAIL',
    REACTIVATE_USER: 'REACTIVATE_USER',
    RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
    TEAMS: 'TEAMS',
    TEAM_OPTIONS: 'TEAM_OPTIONS',
    TEAM: 'TEAM',
    SCHEDULE: 'SCHEDULE',
    SMS_LIST: 'SMS_LIST',
    TAG: 'TAG',
    TAGS: 'TAGS',
    TAG_CATEGORIES: 'TAG_CATEGORIES',
    TAG_CATEGORY: 'TAG_CATEGORY',
    TASK: 'TASK',
    TASK_COMPLETION: 'TASK_COMPLETION',
    TASKS: 'TASKS',
    TASK_TYPES: 'TASK_TYPES',
    TASK_LABELS: 'TASK_LABELS',
    TASK_LABEL: 'TASK_LABEL',
    UPDATE_TASKS: 'UPDATE_TASKS',
    CHECK_TASK_LABEL_DUPLICATE: 'CHECK_TASK_LABEL_DUPLICATE',
    TEMPLATE: 'TEMPLATE',
    TEMPLATES: 'TEMPLATES',
    LEAD_TYPES: 'LEAD_TYPES',
    LEAD_STICKY_NOTE: 'LEAD_STICKY_NOTE',
    LEAD_SOURCES: 'LEAD_SOURCES',
    CHECK_PERMISSION: 'CHECK_PERMISSION',
    CHECK: 'CHECK',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    FAVORITES: 'FAVORITES',
    ACCESS_ROLES: 'ACCESS_ROLES',
    ACCESS_ROLE: 'ACCESS_ROLE',
    MMS: 'MMS',
    SMS: 'SMS',
    MMS_PAYLOAD: 'MMS',
    FAVORITES_CATEGORIES: 'FAVORITES_CATEGORIES',
    CONTACTS: 'CONTACTS',
    CONTACTS_BULK: 'CONTACTS_BULK',
    RECENT_CONTACTS: 'RECENT_CONTACTS',
    ACCOUNT_CONTACT: 'ACCOUNT_CONTACT',
    UPLOAD_BLAST_LIST: 'UPLOAD_BLAST_LIST',
    BLAST_LISTS: 'BLAST_LISTS',
    BLAST_LIST: 'BLAST_LIST',
    BLAST_LIST_CATEGORY: 'BLAST_LIST_CATEGORY',
    CUSTOM_FIELDS_FIELD: 'CUSTOM_FIELDS_FIELD',
    CUSTOM_FIELDS_GROUP: 'CUSTOM_FIELDS_GROUP',
    CUSTOM_FIELDS_GROUPS: 'CUSTOM_FIELDS_GROUPS',
    CUSTOM_FIELDS_PARENT_GROUPS: 'CUSTOM_FIELDS_PARENT_GROUPS',
    ACCOUNT: 'ACCOUNT',
    AUTH_USER: 'AUTH_USER',
    AUTH_ROLE: 'AUTH_ROLE',
    PERMISSIONS: 'PERMISSIONS',
    CONTACT_CONTACT_LABEL: 'CONTACT_CONTACT_LABEL',
    CONTACT_EMAIL: 'CONTACT_EMAIL',
    CONTACT_LOCATION: 'CONTACT_LOCATION',
    CONTACT_SOCIAL_MEDIA: 'CONTACT_SOCIAL_MEDIA',
    CONTACT_OPTIONS: 'CONTACT_OPTIONS',
    CONTACT_DUPLICATES: 'CONTACT_DUPLICATES',
    CONTACT_RELATIONS: 'CONTACT_RELATIONS',
    CONTACT_NOTES: 'CONTACT_NOTES',
    ACCOUNT_NOTE: 'ACCOUNT_NOTE',
    RECENTS: 'RECENTS',
    ACCOUNT_RECENTS: 'ACCOUNT_RECENTS',
    EVENTS: 'EVENTS',
    EVENT: 'EVENT',
    EVENT_LABELS: 'EVENT_LABELS',
    EVENT_LABEL: 'EVENT_LABEL',
    CHECK_EVENT_LABEL_DUPLICATE: 'CHECK_EVENT_LABEL_DUPLICATE',
    ACCOUNT_LOCATION: 'ACCOUNT_LOCATION',
    CUSTOM_FIELDS: 'CUSTOM_FIELDS',
    CUSTOM_FIELD: 'CUSTOM_FIELD',
    CONTACT_PHONE: 'CONTACT_PHONE',
    CONTACT_AUTOCOMPLETE: 'CONTACT_AUTOCOMPLETE',
    CONTACT_AUTOCOMPLETE_FILTER: 'CONTACT_AUTOCOMPLETE_FILTER',
    FIELD_AUTOCOMPLETE: 'FIELD_AUTOCOMPLETE',
    TERRITORY: 'TERRITORY',
    TERRITORY_COUNTIES: 'TERRITORY_COUNTIES',
    PIPELINE_CATEGORY: 'PIPELINE_CATEGORY',
    PIPELINE: 'PIPELINE',
    PIPELINE_STAGE: 'PIPELINE_STAGE',
    PIPELINE_CONVERT_CONTACT: 'PIPELINE_CONVERT_CONTACT',
    ACCOUNT_AUTOCOMPLETE: 'ACCOUNT_AUTOCOMPLETE',
    ACCOUNT_PIPELINE: 'ACCOUNT_PIPELINE',
    ACCOUNT_TRANSITION: 'ACCOUNT_TRANSITION',
    PIPELINE_TRANSITION: 'PIPELINE_TRANSITION',
    NOTIFICATION_CENTER: 'NOTIFICATION_CENTER',
    NOTIFICATION_HUB: 'NOTIFICATION_HUB',
    NOTIFICATION_ARCHIVE: 'NOTIFICATION_ARCHIVE',
    NOTIFICATION_MARK_READ: 'NOTIFICATION_MARK_READ',
    NOTIFICATION_CLEAR: 'NOTIFICATION_CLEAR',
    NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
    UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
    NOTIFICATION_BULK_ACTION: 'NOTIFICATION_BULK_ACTION',
    TEXT_TEMPLATES: 'TEXT_TEMPLATES',
    EMAIL_TEMPLATES: 'EMAIL_TEMPLATES',
    PHONE_TEXT: 'PHONE_TEXT',
    LIMIT_TEXT: 'LIMIT_TEXT',
    CONVERSATION_TEXT: 'CONVERSATION_TEXT',
    MESSAGES_TEXT: 'MESSAGES_TEXT',
    STATUS_TEXT: 'STATUS_TEXT',
    ACCOUNT_RELATIONSHIPS: 'ACCOUNT_RELATIONSHIPS',
    TEAM_USER: 'TEAM_USER',
    CONTACT_HISTORY: 'CONTACT_HISTORY',
    TEXT_TEMPLATE: 'TEXT_TEMPLATE',
    FILTER_MESSAGES_TEXT: 'FILTER_MESSAGES_TEXT',
    CONVERT_TO_CUSTOMER: 'CONVERT_TO_CUSTOMER',
    EMAIL_PROVIDERS: 'EMAIL_PROVIDERS', //deprecated
    EMAIL_ENABLE: 'EMAIL_ENABLE', //deprecated
    OAUTH_PRIMARY_INTEGRATION: 'OAUTH_PRIMARY_INTEGRATION',
    OAUTH_UPDATE_PRIMARY_INTEGRATION: 'OAUTH_UPDATE_PRIMARY_INTEGRATION',
    OAUTH_INTEGRATIONS: 'OAUTH_INTEGRATIONS',
    OAUTH_INTEGRATION_ENABLE: 'OAUTH_ENABLE',
    OAUTH_INTEGRATION_REACTIVATE: 'OAUTH_INTEGRATION_REACTIVATE',
    EMAIL_PULL: 'EMAIL_PULL',
    EMAIL_SEND: 'EMAIL_SEND',
    EMAIL_GET_ATTACHMENT: 'EMAIL_GET_ATTACHMENT',
    DELETE_EMAIL_INTEGRATION: 'DELETE_EMAIL_INTEGRATION', // deprecated
    UPDATE_PRIMARY_EMAIL: 'UPDATE_PRIMARY_EMAIL', // deprecated
    DELETE_OAUTH_INTEGRATION: 'DELETE_OAUTH_INTEGRATION',
    PBX_CONFIG_DOMAIN: 'PBX_CONFIG_DOMAIN',
    PBX_CONFIG_DOMAIN_EXTENSIONS: 'PBX_CONFIG_DOMAIN_EXTENSIONS',
    PBX_CONFIG_VOICEMAIL_BOX: 'PBX_CONFIG_VOICEMAIL_BOX',
    PBX_CONFIG_LOCATIONS: 'PBX_CONFIG_LOCATIONS',
    PBX_CONFIG_LOCATION: 'PBX_CONFIG_LOCATION',
    PBX_CONFIG_PHONE_LINES: 'PBX_CONFIG_PHONE_LINES',
    PBX_CONFIG_CALL_QUEUES: 'PBX_CONFIG_CALL_QUEUES',
    PBX_CONFIG_RING_GROUPS: 'PBX_CONFIG_RING_GROUPS',
    PBX_CONFIG_VALIDATE_LOCATION: 'PBX_CONFIG_VALIDATE_LOCATION',
    PBX_CONFIG_CID_OPTIONS: 'PBX_CONFIG_CID_OPTIONS',
    PBX_CONFIG_COMPANY_EXTENSION: 'PBX_CONFIG_COMPANY_EXTENSION', 
    PBX_CONFIG_LIST_EXTENSIONS:'PBX_CONFIG_LIST_EXTENSIONS',   
    PBX_CONFIG_UNUSED_EXTENSIONS:'PBX_CONFIG_UNUSED_EXTENSIONS', 
    PBX_CONFIG_USER_EXTENSIONS:'PBX_CONFIG_USER_EXTENSIONS',     
    PBX_LIST_PHONES:'PBX_LIST_PHONES',

    PBX_CONFIG_RECORDINGS: "PBX_CONFIG_RECORDINGS",
    PBX_CONFIG_DESTINATION_GROUPS: "PBX_CONFIG_DESTINATION_GROUPS",
    PBX_CONFIG_ROUTING_RULES: "PBX_CONFIG_ROUTING_RULES",
    PBX_CONFIG_COMPANY_VOICEMAIL: "PBX_CONFIG_COMPANY_VOICEMAIL",
    PBX_CONFIG_CONFERENCE_ROOM: "PBX_CONFIG_CONFERENCE_ROOM",
    PBX_CONFIG_SOUND: "PBX_CONFIG_SOUND",
    PBX_CONFIG_SOUNDS: "PBX_CONFIG_SOUNDS",
    PBX_CONFIG_PARK: "PBX_CONFIG_PARK",
    PBX_CONFIG_UNIQUE_NAME: "PBX_CONFIG_UNIQUE_NAME",
    PBX_CONFIG_DESTINATION_GROUP: "PBX_CONFIG_DESTINATION_GROUP",
    PBX_CONFIG_DYLNUMBERS: "PBX_CONFIG_DYLNUMBERS",
    PBX_CONFIG_DESTINATIONS: "PBX_CONFIG_DESTINATIONS",
    PBX_CONFIG_PREFERENCES: "PBX_CONFIG_PREFERENCES",
    PROVISION_DEVICES: 'PROVISION_DEVICES',
    PROVISION_ALL_DEVICES: 'PROVISION_ALL_DEVICES',
    PROVISION_LOCATIONS: 'PROVISION_LOCATIONS',
    PROVISION_LOCATION: 'PROVISION_LOCATION',
    PROVISION_SOFTPHONES: 'PROVISION_SOFTPHONES',
    PROVISION_SOFTPHONE: 'PROVISION_SOFTPHONE',
    PROVISION_DEVICE: 'PROVISION_DEVICE',
    PROVISION_SOFTPHONE_REACTIVATE: 'PROVISION_SOFTPHONE_REACTIVATE',
    PROVISION_SOFTPHONE_DEACTIVATE: 'PROVISION_SOFTPHONE_DEACTIVATE',
    PROVISION_VALIDATE_LOCATION: 'PROVISION_VALIDATE_LOCATION',
    CALL: 'CALL',
    HANGUP: 'HANGUP',
    TRANSFER: 'TRANSFER',
    CUSTOM_FIELD_OPTION: 'CUSTOM_FIELD_OPTION',
    PIPELINE_TRIAL: 'PIPELINE_TRIAL',
    AUTH_TRIAL: 'AUTH_TRIAL',
    AUTH_PASSWORD: 'AUTH_PASSWORD',
    PIPELINE_END_TRIAL: 'PIPELINE_END_TRIAL',
    CLOSE_CUSTOMER_PIPELINE: 'CLOSE_CUSTOMER_PIPELINE',
    CONTACT_RELATIONSHIP: 'CONTACT_RELATIONSHIP',
    CALLS: 'CALLS',
    RECENT_CALLS: 'RECENT_CALLS',
    ACTIVE_CALLS: 'ACTIVE_CALLS',
    CONTACT_UPCOMING_HISTORY: 'CONTACT_UPCOMING_HISTORY',
    CONTACT_LATEST_HISTORY: 'CONTACT_LATEST_HISTORY',
    ACCOUNT_PRIMARY_CONTACT: 'ACCOUNT_PRIMARY_CONTACT',
    PBX_CONFIG_FORWARDINGS: 'PBX_CONFIG_FORWARDINGS',
    PBX_CONFIG_FORWARDING: 'PBX_CONFIG_FORWARDING',
    PBX_CONFIG_USER_EXTENSION: 'PBX_CONFIG_USER_EXTENSION',
    PIPELINE_ACCOUNT_LOST: 'PIPELINE_ACCOUNT_LOST',
    CLOSE_REASONS: 'CLOSE_REASONS',
    PBX_CONFIG_LOCAL_PRESENCE: 'PBX_CONFIG_LOCAL_PRESENCE',
    DASHBOARD: 'DASHBOARD',
    ACCOUNT_PIPELINE_CUSTOM_GROUP: 'ACCOUNT_PIPELINE_CUSTOM_GROUP',
    LEAD_INGEST_LEAD_PROVIDER: 'LEAD_INGEST_LEAD_PROVIDER',
    LEAD_INGEST_PROVIDER_META: 'LEAD_INGEST_PROVIDER_META',
    LEAD_INGEST_MAPPING: 'LEAD_INGEST_MAPPING',
    LEAD_INGESTION_MAIN_SOURCE_PROVIDER: 'LEAD_INGESTION_MAIN_SOURCE_PROVIDER',
    LEAD_INGESTION_PRODUCT_INTERESTS: 'LEAD_INGESTION_PRODUCT_INTERESTS',
    ACCESS_ROLE_ASSIGNMENT: 'ACCESS_ROLE_ASSIGNMENT',
    USER_PROFILE_IMAGE: 'USER_PROFILE_IMAGE',
    IMAGE_URLS: 'IMAGE_URLS',
    CALL_HISTORY_CALLS: 'CALL_HISTORY_CALLS',
    CALL_HISTORY_RECENT: 'CALL_HISTORY_RECENT',
    CALL_HISTORY_ACTIVE: 'CALL_HISTORY_ACTIVE',
    MESSAGES_SEARCH: 'MESSAGES_SEARCH',
    REACTIVATE_TRIAL: 'REACTIVATE_TRIAL',
    PHONE_NUMBER: 'PHONE_NUMBER',
    PHONE_NUMBER_SEARCH: 'PHONE_NUMBER_SEARCH',
    PHONE_NUMBER_ORDER: 'PHONE_NUMBER_ORDER',
    RING_GROUPS: 'RING_GROUPS',
    RING_GROUP: 'RING_GROUP',
    CALL_QUEUES: 'CALL_QUEUES',
    CALL_QUEUE: 'CALL_QUEUE',
    PBX_CONFIG_AREACODE: 'PBX_CONFIG_AREACODE',
    CID_SELECTS: 'CID_SELECTS',
    CID_PREFS: 'CID_PREFS',
    CID_OPTIONS: 'CID_OPTIONS',
    PHONE_NUMBER_DOMAIN: 'PHONE_NUMBER_DOMAIN',
    PRODUCT: 'PRODUCT',
    PRODUCT_ADDONS: 'PRODUCT_ADDONS',
    PRODUCT_FEES: 'PRODUCT_FEES',
    PRODUCT_MANUFACTURER: 'PRODUCT_MANUFACTURER',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    PRODUCT_PLAN: 'PRODUCT_PLAN',
    PRODUCT_QUOTE: 'PRODUCT_QUOTE',
    PROVISION_DOMAIN: 'PROVISION_DOMAIN',
    USER_SOCIAL_MEDIA: 'USER_SOCIAL_MEDIA',
    CALL_QUEUE_MEMBERS: 'CALL_QUEUE_MEMBERS',
    RING_GROUP_MEMBERS: 'RING_GROUP_MEMBERS',
    USER_LOCATION: 'USER_LOCATION',
    USER_COMPANY: 'USER_COMPANY',
    ALL_PRODUCTS: 'ALL_PRODUCTS',
    CUSTOM_FIELD_REACTIVATE: 'CUSTOM_FIELD_REACTIVATE',
    SINGLE_PRODUCT_QUOTE: 'SINGLE_PRODUCT_QUOTE',
    QUOTE_ITEMS: 'QUOTE_ITEMS',
    ACCOUNT_OPPORTUNITIES: 'ACCOUNT_OPPORTUNITIES',
    PAYMENT_FORM_TOKEN: 'PAYMENT_FORM_TOKEN',
    ORDER_QUOTE: 'ORDER_QUOTE',
    PAYMENT_CREATE_CUSTOMER_PROFILE: 'PAYMENT_CREATE_CUSTOMER_PROFILE',
    VIEW_USERS: 'VIEW_USERS',
    USER_ACCOUNTS: 'USER_ACCOUNTS',
    USER_OPTIONS: 'USER_OPTIONS',
    BULK_USERNAMES: 'BULK_USERNAMES',
    CUSTOM_FIELDS_STANDARD_GROUP: 'CUSTOM_FIELDS_STANDARD_GROUP',

    PRODUCT_INTEREST_OPTIONS: 'PRODUCT_INTEREST_OPTIONS',

    USER_CONVERSION_DATA: 'USER_CONVERSION_DASHBOARD',
    USER_FUNNEL_DATA: 'USER_FUNNEL_DATA',
    TEAM_CONVERSION_DATA: 'TEAM_CONVERSION_DATA',
    TEAM_FUNNEL_DATA: 'TEAM_FUNNEL_DATA',
    PRODUCT_QUOTE_SHIPPING: 'PRODUCT_QUOTE_SHIPPING',
    DOWNLOAD_FILE: 'DOWNLOAD_FILE',

    SELF_REPORT_CALL: 'SELF_REPORT_CALL',
    USER_CALL_ACTIVITY_LOG: 'USER_CALL_ACTIVITY_LOG',
    CUSTOMER_CALL_ACTIVITY_LOG: 'CUSTOMER_CALL_ACTIVITY_LOG',

    SELF_REPORT_TEXT: 'SELF_REPORT_TEXT',
    USER_TEXT_ACTIVITY_LOG: 'USER_TEXT_ACTIVITY_LOG',
    CUSTOMER_TEXT_ACTIVITY_LOG: 'CUSTOMER_TEXT_ACTIVITY_LOG',

    SELF_REPORT: 'SELF_REPORT',
    SELF_REPORT_EMAIL: 'SELF_REPORT_EMAIL',
    USER_EMAIL_ACTIVITY_LOG: 'USER_EMAIL_ACTIVITY_LOG',
    CUSTOMER_EMAIL_ACTIVITY_LOG: 'CUSTOMER_EMAIL_ACTIVITY_LOG',

    AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
    AUTH_SEND_PASSWORD_RECOVERY_LINK: 'AUTH_SEND_PASSWORD_RECOVERY_LINK',
    AUTH_VERIFY_CODE: 'AUTH_VERIFY_CODE',
    AUTH_DEACTIVATE_USER: 'AUTH_DEACTIVATE_USER',
    AUTH_REACTIVATE_USER: 'AUTH_REACTIVATE_USER',

    HOTLIST_CHECK_CONTACTS: 'HOTLIST_CHECK_CONTACTS',
    USER_PROFILE: 'USER_PROFILE',
    MODAL_CUSTOM_FIELDS_STANDARD_GROUP: 'MODAL_CUSTOM_FIELDS_STANDARD_GROUP',
    PAYMENT_PROFILE: 'PAYMENT_PROFILE',
    FUND_PAYMENT_PROFILE: 'FUND_PAYMENT_PROFILE',
    DEACTIVATE_PAYMENT_PROFILE: 'DEACTIVATE_PAYMENT_PROFILE',
    USER_PASSWORD: 'USER_PASSWORD',
    USER_RESET_FORGOTTEN_PASSWORD: 'USER_RESET_FORGOTTEN_PASSWORD',

    COMPANY: 'COMPANY',
    COMPANY_LOCATIONS: 'COMPANY_LOCATIONS',
    COMPANY_PHONES: 'COMPANY_PHONES',
    COMPANY_SOCIAL_MEDIAS: 'COMPANY_SOCIAL_MEDIAS',
    COMPANY_INDUSTRY: 'COMPANY_INDUSTRY',
    COMPANY_SIGNATURE: 'COMPANY_SIGNATURE',
    COMPANY_SIGNATURE_PREFERENCES: 'COMPANY_SIGNATURE_PREFERENCES',
    COMPANY_LOGO: 'COMPANY_LOGO',

    USER_SIGNATURE: 'USER_SIGNATURE',
    USER_SIGNATURE_PREFERENCES: 'USER_SIGNATURE_PREFERENCES',
    TEMPLATE_CATEGORY: 'TEMPLATE_CATEGORY',
    EMAIL_ATTACHMENTS: 'EMAIL_ATTACHMENTS',
    UPLOAD_FILE: 'UPLOAD_FILE',
    EVENT_ATTENDEES: 'EVENT_ATTENDEES',
    EVENTS_TAB: 'EVENTS_TAB',
    EVENT_ATTACHMENTS: 'EVENT_ATTACHMENTS',
    TASKS_TAB: 'TASKS_TAB',
    OAUTH_SETUP: 'OAUTH_SETUP',
    TASK_ATTACHMENTS: 'TASK_ATTACHMENTS',

    WEBSOCKET_CONNECT: 'WEBSOCKET_CONNECT',

    TEXT_ATTACHMENTS: 'TEXT_ATTACHMENTS',
    WEBFORMS: 'WEBFORMS',
    WEBFORM_PUBLISH: 'WEBFORM_PUBLISH',
    WEBFORM_STATUS: 'WEBFORM_STATUS',
    CATEGORIZED_TEMPLATES: 'CATEGORIZED_TEMPLATES',

    LEAD_PROVIDERS: 'LEAD_PROVIDERS',
    LEAD_PROVIDER_NAMES: 'LEAD_PROVIDER_NAMES',
    UNMAPPED_LEAD_PROVIDERS: 'UNMAPPED_LEAD_PROVIDERS',
    LEAD_PROVIDER_MAPPINGS: 'LEAD_PROVIDER_MAPPINGS',
    LEAD_PROVIDER_MAPPING: 'LEAD_PROVIDER_MAPPING',
    IS_EXISTING_LEAD_PROVIDER_MAPPING: 'IS_EXISTING_LEAD_PROVIDER_MAPPING',
    LEAD_PROVIDER_SECONDARY_SOURCES: ' LEAD_PROVIDER_SECONDARY_SOURCES',
    DATA_RECORD: 'DATA_RECORD',
    LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS: 'LEAD_PROVIDER_SECONDARY_SOURCE_OPTIONS',
    MASTER_ACCOUNT: 'MASTER_ACCOUNT',
    LEAD_INGESTION_ALL: 'LEAD_INGESTION_ALL',
    LEAD_INGESTION: 'LEAD_INGESTION',
    LEAD_INGESTION_EMAIL_PARSE: 'LEAD_INGESTION_EMAIL_PARSE',
    LEAD_INGESTION_EMAIL_SAVE_PARSER: 'LEAD_INGESTION_EMAIL_SAVE_PARSER',
    LEAD_INGESTION_PROVIDER_RECORD: 'LEAD_INGESTION_PROVIDER_RECORD',
    LEAD_INGESTION_MAPPING_FIELDS: 'LEAD_INGESTION_MAPPING_FIELDS',

    ACCOUNT_PROFILE: 'ACCOUNT_PROFILE',
    ACCOUNT_OPTIONS: 'ACCOUNT_OPTIONS',
    ACCOUNT_CONTACTS: 'ACCOUNT_CONTACTS',
    LINK_CONTACTS_TO_ACCOUNT: 'LINK_CONTACTS_TO_ACCOUNT',
    UNLINK_CONTACTS_TO_ACCOUNT: 'UNLINK_CONTACTS_TO_ACCOUNT',
    MASTER_ACCOUNT_ACCOUNTS: 'MASTER_ACCOUNT_ACCOUNTS',
    MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS: 'MASTER_ACCOUNT_ACCOUNT_PIN_OPTIONS',
    ACCOUNT_CONTACTS_PIN_OPTIONS: 'ACCOUNT_CONTACTS_PIN_OPTIONS',

    IMPORT: 'IMPORT',
    IMPORT_CSV: 'IMPORT_CSV',
    ACCOUNT_CONTACT_IDS: 'ACCOUNT_CONTACT_IDS',

    SEND_EMAIL_CONTACT: 'SEND_EMAIL_CONTACT',
    SEND_EMAIL_LOCATION: 'SEND_EMAIL_LOCATION',

    LEADS_TAB: 'LEADS_TAB',

    OPPORTUNITY_TAB: 'OPPORTUNITY_TAB',
    PIPELINE_TRANSFER: 'PIPELINE_TRANSFER',
    PIPELINE_TIMELINE: 'PIPELINE_TIMELINE',

    TERRITORY_DUPLICATE_CHECK: 'TERRITORY_DUPLICATE_CHECK',
    TERRITORY_VALIDITY_CHECK: 'TERRITORY_VALIDATION_CHECK',
    CUSTOM_FIELDS_MAPPING: 'CUSTOM_FIELDS_MAPPING',
    AUTOMATION_FIELDS: 'AUTOMATION_FIELDS',

    CONTACT_STANDARD_GROUP: 'CONTACT_STANDARD_GROUP',
    CUSTOM_FIELD_GROUP_REACTIVATE: 'CUSTOM_FIELD_GROUP_REACTIVATE',
    CUSTOMER_STANDARD_GROUP: 'CUSTOMER_STANDARD_GROUP',

    GROUPS: "GROUPS",
    GROUP_OPTIONS: "GROUP_OPTIONS",
    MOVE_GROUPS: "MOVE_GROUPS",
    UNIQUE_GROUP: "UNIQUE_GROUP",
    GROUP_CONTACTS: "GROUP_CONTACTS",
    CONTACT_GROUPS: "CONTACT_GROUPS",
    SUBGROUPS: "SUBGROUPS",
    GROUPS_BULK: "GROUPS_BULK",
    GROUPS_HOTLIST: "GROUPS_HOTLIST",
    BULK_HOTLIST: "BULK_HOTLIST",
    HOTLIST_CHECK: "HOTLIST_CHECK",

    PAUSE_CUSTOMER: 'PAUSE_CUSTOMER',
    CANCEL_CUSTOMER: 'CANCEL_CUSTOMER',
    MASTER_ACCOUNT_ACCOUNT: 'MASTER_ACCOUNT_ACCOUNT',
    MASTER_ACCOUNTS_SEARCH: 'MASTER_ACCOUNTS_SEARCH',

    CAMPAIGN_CATEGORY_OPTIONS: 'CAMPAIGN_CATEGORY_OPTIONS',
    CAMPAIGNS: 'CAMPAIGN',
    CAMPAIGNS_LIST: 'CAMPAIGNS_LIST',
    CAMPAIGN_CONVERTED: 'CAMPAIGN_CONVERTED',
    CAMPAIGN_BULK_ACTION: 'CAMPAIGN_BULK_ACTION',
    REACTIVATE_CAMPAIGN: 'REACTIVATE_CAMPAIGN',
    CAMPAIGN_USER_REPORTS: 'CAMPAIGN_USER_REPORTS',
    CAMPAIGN_USER_IDS: 'CAMPAIGN_USER_IDS',

    CHECK_CAMPAIGN_DUPLICATE: 'CHECK_CAMPAIGN_DUPLICATE',
    CAMPAIGN_HISTORY: 'CAMPAIGN_HISTORY',
    CAMPAIGN_CONTACTS: 'CAMPAIGN_CONTACTS',

    ASSIGNMENT_TERRITORIES_TEAMS: 'ASSIGNMENT_TERRITORIES_TEAMS',
    ASSIGNMENT_UNIQUE_NAME: 'ASSIGNMENT_UNIQUE_NAME',
    
    CONTACT_CAMPAIGNS: 'CONTACT_CAMPAIGNS',
    CUSTOMER_PIPELINE: 'CUSTOMER_PIPELINE',

    IS_UNIQUE_SEQUENCE: 'IS_UNIQUE_SEQUENCE',
    SEQUENCE: 'SEQUENCE',
    SEQUENCE_SHORT: 'SEQUENCE_SHORT',
    ADD_TO_SEQUENCE: 'ADD_TO_SEQUENCE',
    SEQUENCE_TASK: 'SEQUENCE_TASK',
    RESCHEDULE_SEQUENCE_TASK_STATS: 'RESCHEDULE_SEQUENCE_TASK_STATS',
    RESCHEDULE_SEQUENCE_TASK: 'RESCHEDULE_SEQUENCE_TASK',
    COMPLETE_SEQUENCE_TASK: 'COMPLETE_SEQUENCE_TASK',
    SEQUENCE_CONTACT_TASKS: 'SEQUENCE_CONTACT_TASKS',
    SEQUENCE_USERS: 'SEQUENCE_USERS',

    CONTACT_SEQUENCE: 'CONTACT_SEQUENCE',
    CONTACT_SEQUENCE_CONVERT: 'CONTACT_SEQUENCE_CONVERT',
    CONTACT_SEQUENCE_REASSIGN: 'CONTACT_SEQUENCE_REASSIGN',

    CAMPAIGN_USERS: 'CAMPAIGN_USERS',
    ASSIGN_OWNERS: 'ASSIGN_OWNERS',
    ASSIGN_TEAMS_TERRITORIES: 'ASSIGN_TEAMS_TERRITORIES',
    CHANGE_MODULE_PIPELINE: 'CHANGE_MODULE_PIPELINE',
    CHECK_PRODUCT_DUPLICATE: 'CHECK_PRODUCT_DUPLICATE',

    SUPPLY_CHAIN_NETWORK: 'SUPPLY_CHAIN_NETWORK',
    PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
    PRODUCT_CATALOG: 'PRODUCT_CATALOG',

    QUOTE: 'QUOTE',
    OPPORTUNITY_RECENT_QUOTE: 'OPPORTUNITY_RECENT_QUOTE',
    OPPORTUNITY_TAB_VIEW_QUOTES: 'OPPORTUNITY_TAB_VIEW_QUOTES',
    QUOTE_VERSION: 'QUOTE_VERSION',

    QUOTE_CONTACT_INFORMATION: 'QUOTE_CONTACT_INFORMATION',
    QUOTE_VERSIONS: 'QUOTE_VERSIONS',
    QUOTE_STATUS: 'QUOTE_STATUS',

    MOVE_CONTACT: 'MOVE_CONTACT',

    QUOTE_VERSIONS_SUMMARY: 'QUOTE_VERSIONS_SUMMARY',

    CUSTOMER_PIPELINE_TIMELINE: 'CUSTOMER_PIPELINE_TIMELINE',

    ORDER: 'ORDER',
    CONTACT_PHONE_OPTIONS: 'CONTACT_PHONE_OPTIONS',
    CONTACT_EMAIL_OPTIONS: 'CONTACT_EMAIL_OPTIONS',
    CONTACT_LOCATION_OPTIONS: 'CONTACT_LOCATION_OPTIONS',

    INVOICE: 'INVOICE',
    RECENT_ORDER: 'RECENT_ORDER',

    ORDER_ADDRESSES: 'ORDER_ADDRESSES',
    QUOTE_ADDRESS: 'QUOTE_ADDRESS',

    QUOTES_AND_ORDERS: 'QUOTES_AND_ORDERS',

    ORDERS: 'ORDERS',
    ORDER_INVOICES: 'ORDER_INVOICES'
}
