import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, EllipsisDropdown, DateTimeUtils, VoicemailPlayback, HoverList, ClippedContent, ControlledPopup } from 'dyl-components';
import { Icon, Checkbox, DropdownItem, Popup, Header } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import '../index.scss';

const Row = ({ sound = {}, onOpenEditSoundsModal, onDeleteSound }) => {
    const { apply_to, label, last_modified, sound_category, sound_id, url, in_use } = sound;
    const [isVoicemailPlaybackOpen, setIsVoicemailPlaybackOpen] = useState(false);
    const [selectedSounds, setSelectedSounds] = useContext(BulkActionsContext);

    const isSelected = (sound_id) => {
        return selectedSounds.includes(sound_id);
    }

    const onToggleNumber = (sound_id) => {
        const newSelectedSounds = isSelected(sound_id) ? selectedSounds.filter(id => sound_id !== id) : [...selectedSounds, sound_id];
        setSelectedSounds(newSelectedSounds);
    }

    const getSoundType = (type) => {
        return PhoneUtil.SOUND_TYPES.find(({ value }) => value === type).text
    }

    const applyToFormat = (applyToList) => {
        let applyToArray = [];
        applyToList.forEach(item => {
            const link = <Link to={PhoneUtil.DESTINATION_TYPES_URLS[item.type] || PhoneUtil.DESTINATION_TYPES_URLS['company_voicemail']}>{item.label}</Link>;
            const name = <span><Icon className={item.type === 'vmdrop' && item?.user ? PhoneUtil.DESTINATION_TYPES_AUDIO_ICONS['direct_extension'] : PhoneUtil.DESTINATION_TYPES_AUDIO_ICONS[item.type]} />{item.type === 'vmdrop' && item?.user ? `${item.user?.first_name} ${item.user?.last_name}` : link}{item.alias && ` (Ext. ${item.alias})`}</span>;
            applyToArray.push(name);
        })
        return applyToArray;
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(sound_id)} onChange={() => { onToggleNumber(sound_id) }} />
            </Table.Cell>
            <Table.Cell>
                <div><b>{DateTimeUtils.formatEpoch(last_modified, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                <div>{DateTimeUtils.formatEpoch(last_modified, DateTimeUtils.TIME_FORMAT)}</div>
            </Table.Cell>
            <Table.Cell>
                {getSoundType(sound_category)}
            </Table.Cell>
            <Table.Cell>
                <HoverList 
                    list={applyToFormat(apply_to)}
                    scroll
                /> 
            </Table.Cell>
            <Table.Cell>
                <Popup
                    style={{minWidth:400}}
                    trigger={
                        <Icon className="fa-solid fa-music" size="large" disabled={!!!url}/>
                    }
                    on="click"
                    content={
                        <VoicemailPlayback
                            onClose={() => setIsVoicemailPlaybackOpen(false)}
                            className={`audio-playback-${sound_id}`}
                            fileName={label}
                            link={url}
                        />
                    }
                    position="bottom center"
                    open={isVoicemailPlaybackOpen}
                    onOpen={() => setIsVoicemailPlaybackOpen(true)}
                />
            </Table.Cell>
            <Table.Cell>
                <ClippedContent>
                    {label}
                </ClippedContent>
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => onOpenEditSoundsModal(sound_id)}
                    />
                    <ControlledPopup
                        header={
                            <Header as='h4' textAlign='center'>
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    You cannot undo delete actions.
                                </Header.Subheader>
                            </Header>
                        }
                        trigger={
                            <DropdownItem 
                                icon={'fa-solid fa-trash'} 
                                text={"Delete"}
                                disabled={!!in_use}
                            />
                        }
                        onConfirm={() => onDeleteSound(sound_id)}
                        inverted
                    />
                    
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


