import React, { useContext } from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import { ButtonLink, ClippedContent, DateTimeUtils, Table } from 'dyl-components';

import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';
import { StringUtils } from 'utils';
import { useParams } from 'react-router-dom';

const Subrows = ({ invoices }) => {
    const { onViewInvoice } = useContext(QuoteBuilderContext);

    const { account_id } = useParams();

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={7}>
                <div className="OrdersTable__OrderInvoicesTable">
                    <Table striped={false}>
                        <SemanticTable.Header className='Table__header--primary'>
                            <SemanticTable.Row>
                                <SemanticTable.HeaderCell>
                                    Invoice Date
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Due On
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell className="OrderInvoicesTable__InvoiceIDColumn">
                                    Invoice ID
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Billed Amount
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Payment Method
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Invoice Status
                                </SemanticTable.HeaderCell>
                            </SemanticTable.Row>
                        </SemanticTable.Header>
                        <Table.Body>
                            {invoices.map(invoice => (
                                <Table.Row>
                                    <SemanticTable.Cell>
                                        <div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    invoice.created,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {invoice.due_on > 0 && (<div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    invoice.due_on,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>)}
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        <ButtonLink onClick={() => { onViewInvoice(invoice.id, account_id) }}><ClippedContent>{invoice.id}</ClippedContent></ButtonLink>
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {invoice.amount &&
                                            `$${StringUtils.formatDecimal(`${invoice.amount}`, true)}`}
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>

                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {StringUtils.capitalize(invoice.invoice_status)}
                                    </SemanticTable.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
}

export default Subrows;
