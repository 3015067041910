import React, { useContext } from "react";
import { Icon, Grid } from "semantic-ui-react";
import { ButtonLink, ClippedContent, DateTimeUtils } from "dyl-components";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { useSelector } from "react-redux";
import { StringUtils } from "utils";
import { useParams } from "react-router-dom";

const CustomerRecentOrder = ({ textAlign = "right" }) => {
    const { onViewInvoice } = useContext(QuoteBuilderContext);
    const { account_id } = useParams();
    const recentOrder = useSelector((state) => {
        const recentOrder = state.order.recentOrder;
        return recentOrder?.order_id
            ? {
                  one_time_amount: `$${StringUtils.formatDecimal(
                      `${recentOrder.one_time_amount || 0}`,
                      true
                  )}`,
                  recurring_amount: `$${StringUtils.formatDecimal(
                      `${recentOrder.recurring_amount || 0}`,
                      true
                  )}`,
                  subtotal: `$${StringUtils.formatDecimal(
                      `${recentOrder.subtotal || 0}`,
                      true
                  )}`,
                  order_status: StringUtils.capitalize(recentOrder.order_status),
                  order_date: DateTimeUtils.changeFormat(
                      DateTimeUtils.convertUnixTimeToDate(
                          recentOrder.order_date,
                          "UTC"
                      ),
                      "",
                      DateTimeUtils.DATE_FORMAT,
                      "UTC"
                  ),
                  invoice_date: recentOrder?.invoice_date ? DateTimeUtils.changeFormat(
                      DateTimeUtils.convertUnixTimeToDate(
                          recentOrder.invoice_date,
                          "UTC"
                      ),
                      "",
                      DateTimeUtils.DATE_FORMAT,
                      "UTC"
                  ) : "None",
                  invoice_id: recentOrder?.invoice_id,
              }
            : {
                  one_time_amount: "None",
                  recurring_amount: "None",
                  subtotal: "None",
                  order_status: "None",
                  order_date: "None",
                  invoice_date: "None",
              };
    });

    return (
        <Grid className="RecordInfo">
            <Grid.Row columns="equal">
                <Grid.Column><b>Current Invoice</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    <div className="CurrentInvoiceColumn">
                        {Boolean(recentOrder?.invoice_id) ? (
                            <Grid>
                                <Grid.Row columns="equal">
                                    <Grid.Column textAlign="right">
                                        <ButtonLink
                                            className="CurrentInvoiceValue"
                                            noPadding
                                        >
                                            <ClippedContent>
                                                {recentOrder?.invoice_id}
                                            </ClippedContent>
                                        </ButtonLink>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Icon
                                            link
                                            className="fas fa-copy"
                                            onClick={StringUtils.copy(
                                                recentOrder.invoice_id
                                            )}
                                            color="primary"
                                        />
                                        <Icon
                                            onClick={() => {
                                                onViewInvoice(
                                                    recentOrder?.invoice_id,
                                                    account_id
                                                );
                                            }}
                                            className="fas fa-file-pdf"
                                            link
                                            color="primary"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        ) : (
                            "None"
                        )}
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>One-Time Amount</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.one_time_amount}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>Recurring Amount</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.recurring_amount}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>Subtotal</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.subtotal}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>Order Status</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.order_status}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>Order Date</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.order_date}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column><b>Next Invoice Date</b></Grid.Column>
                <Grid.Column textAlign={textAlign}>
                    {recentOrder.invoice_date}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CustomerRecentOrder;
