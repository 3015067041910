import React, { useCallback, useEffect, useState } from 'react';
import { Notification, STATUS_TYPES, TableWithHeader } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { useDispatch } from 'react-redux';
import provisionActions from 'actions/provision';
import Table from './subcomponents/Table';
import '../index.scss';
import DevicesModal from 'shared/modals/DevicesModal/DevicesModal';

const DEVICES_URL = "/settings/phone-management/devices";

const Devices = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [hardphoneBeingEdited, setHardphoneBeingEdited] = useState(false);

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${DEVICES_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
        status: params.get('status')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onOpenModal = () => {
        setIsEditing(false)
        setHardphoneBeingEdited(null)
        setIsModalOpen(true);
    }

    const onCloseModal = () => {
        setIsEditing(false)
        setHardphoneBeingEdited(null)
        setIsModalOpen(false);
    }

    const onEditDevice = async (id, type) => {
        try {
            if (type === "softphone") {
                await dispatch(provisionActions.getSoftphone(id))
                setIsEditing("softphone")
            } else if (type === "hardphone") {
                await dispatch(provisionActions.getDevice(id))
                setIsEditing("hardphone")
                setHardphoneBeingEdited(id)
            }
            setIsModalOpen(true);
        } catch (error) {
            console.log(error)
            setIsEditing(false)
            setIsModalOpen(false);
            setHardphoneBeingEdited(null)
        }
    }

    const onReload = useCallback(() => {
        dispatch(provisionActions.getAllDevices(params));
    }, [dispatch, params])
    
    const onDeleteDevice = async (id, type) => {
        try {
            if (type === "softphone") {
                await dispatch(provisionActions.deleteSoftphone(id))
            } else if (type === "hardphone") {
                await dispatch(provisionActions.deleteDevice(id))
            }
            Notification.alert('Device deleted Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete device', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        const { type, status } = filters;
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }
        const query_string = query.toString();
        navigate(`${DEVICES_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        onReload();
    }, [onReload])

    return <div className='Devices'>
        <TableWithHeader
            header={<Toolbar onOpenModal={onOpenModal} />}
            table={
                <Table
                    onFilter={onFilter}
                    onPageChange={onPageChange}
                    onOpenModal={() => {}}
                    onEditDevice={onEditDevice}
                    onDeleteDevice={onDeleteDevice}
                />
            }
        />
        <DevicesModal open={isModalOpen} onClose={onCloseModal} onReload={onReload} isEditing={isEditing} hardphoneBeingEdited={hardphoneBeingEdited} />
    </div>
}

export default () => (
    <BulkActionsProvider>
        <Devices />
    </BulkActionsProvider>
);