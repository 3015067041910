import { VALIDATORS, yup } from "dyl-components";
import { cartItemSchema } from "../cart/cartSchema";

export const orderSchema = {
    order_status: yup.string().required("This field is required"),
    billing_address: yup.object({
        address: yup.object({
            street: yup
                .string()
                .maxlength(100)
                .required("This field is required"),
            additional_street: yup.string().maxlength(12),
            city: yup
                .string()
                .maxlength(60)
                .required("This field is required"),
            state: yup.string().required("This field is required"),
            zip: VALIDATORS.US_POSTAL_CODE().required(
                "This field is required"
            ),
        }),
    }),
    shipping_address: yup.object({
        address: yup.object({
            street: yup
                .string()
                .maxlength(100)
                .required("This field is required"),
            additional_street: yup.string().maxlength(12),
            city: yup
                .string()
                .maxlength(60)
                .required("This field is required"),
            state: yup.string().required("This field is required"),
            zip: VALIDATORS.US_POSTAL_CODE().required(
                "This field is required"
            ),
        }),
        phone: VALIDATORS.PHONE_NUMBER().required(
            "This field is required"
        ),
        email: VALIDATORS.EMAIL_ADDRESS().required(
            "This field is required"
        ),
    }),
    cart: yup
        .array()
        .min(1, "There should be at least one item selected")
        .of(yup.object(cartItemSchema)),
}
